.reaction {
    width: 100%;
    height: 100%;
}

.ball {
    transform: rotateZ(131deg);
    position: relative;
    opacity: 0;
    transition: transform 200ms ease-in, opacity 200ms ease-out;
}

.up {
    transform: rotateZ(131deg) translate(-62%, 60%);
}

.reaction__controller {
    position: absolute;
    z-index: 2000;
    top:16px;
    right: 0;
}

.reaction__controller__button {
    border: none;
    width: 48px;
    height: 48px;
    font-size: 32px;
    margin-right: 16px;

    background-color: #fff;
    box-shadow: 0 5px 7px 0 #00000087;

}

.reaction__button {
    justify-self: end;
    font-size: 2em;
    color: var(--color-main);
    background-color: #fff;
    border: none;
    border-radius: 16px;

    margin-bottom: 32px;

    height: 56px;
    width: 200px;

    box-shadow: 0 5px 7px 0 #00000087;
}

.reaction__overlay {
    position: absolute;
    top: 0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff42;
}

.reaction_overlay__menu {
    height: 100%;
    display: flex;
    place-content: center center;
    flex-direction: column;
}