.input-number__wrapper {
    display: flex;
    font-size: 2em;
}

.input-number__value {
    width: 150px;
    height: 48px;
    font-weight: bold;
    line-height: 48px;
    display: flex;
    place-content: center;
}

.input-number__button {
    border: none;
    all: unset;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    place-content: center center;
    color: var(--color-main);
    background-color: #fff;
    border-radius: 50%;
    font-size: 42px;
    line-height: 42px;
    box-shadow: 0 5px 7px 0 #00000087;
}