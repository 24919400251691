.menu {
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  width: 100vw;
  box-sizing: border-box;
  padding: 0 8px;
  list-style: none;
}
.menu__element {
  width: 100%;
  height: 58px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0 5px 7px 0 #00000087;

  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 4px;
}

.menu__element:not(:last-child) {
  margin-bottom: 12px;
}
