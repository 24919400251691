.App {
  overflow: hidden;
  text-align: center;
  background-color: var(--color-main);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}
